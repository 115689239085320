import * as React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../consts";

const Container = styled.div`
  position: relative;
  display: flex;
  text-align: right;
  border-radius: 10px;
  padding: 3rem 2rem;
  box-sizing: border-box;
  width: 100%;
  background: rgb(32 30 42 / 60%);
  overflow: hidden;
  div.vertical {
    position: relative;
    z-index: 10;
    width: 70%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex: 1;
    padding-left: 4rem;
    h1 {
      margin-bottom: 0.3rem;
    }
  }
  div.image-container {
    position: absolute;
    top: -18%;
    width: 34%;
    right: 14%;
    z-index: 20;
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    padding: 3rem 1rem;

    div.image-container {
      display: none;
    }
    div.vertical { 
      display: block;
      width: 100%;
      text-align: center;
      padding: 0;
      h1 {
        margin-bottom: 1.2rem !important;
      }
      p {
        margin-bottom: 2.2rem !important;
      }
      .app-buttons-container > div > a {
        padding: 0;
        margin: 0 .5rem 1rem 0;
      }
    }
  }
`;

const BottomLeftGradient = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  bottom: -43%;
  left: -13%;
  background: radial-gradient(
    circle closest-side,
    rgba(73, 97, 234, 0.3),
    rgba(0, 0, 0, 0) 100%
  );
`;

const TopRightGradient = styled.div`
  position: absolute;
  width: 650px;
  height: 650px;
  top: -58%;
  right: -2%;
  background: radial-gradient(
    circle closest-side,
    rgba(118, 86, 238, 0.4),
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.7;
`;

export default function DownloadApp({ children }) {
  return (
    <Container>
      <BottomLeftGradient />
      {children}
      <TopRightGradient />
    </Container>
  );
}
