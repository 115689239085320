import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
  border-radius: 30px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
`;

const Target = styled.div`
  position: relative;
  cursor: pointer;

  .video-thumbnail {
    z-index: 10;
    transition: opacity 0.2s ease-in-out 0s;
  }

  iframe {
    aspect-ratio: 16 / 9;
    outline: none;
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border: none;
    z-index: 1;
  }

  &:hover {
    .video-thumbnail {
      opacity: 0;
    }
  }

  .video-thumbnail-hover {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default function VideoContainer() {
  const frame = React.createRef();
  const [frameLoaded, setFrameLoaded] = React.useState(false);

  const playVideo = () => {
    if (!frameLoaded) {
      setFrameLoaded(true);
      const iframe = frame.current;
      iframe.allow =
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;";
      iframe.allowFullScreen = "true";
      iframe.src = "https://www.youtube.com/embed/Vq8ZHn4y6nw?autoplay=1";
      iframe.style.zIndex = "11";

      document.querySelector(".video-thumbnail").style.opacity = "0";
      document.querySelector(".video-thumbnail-hover").style.opacity = "0";
    }
  };

  return (
    <Container>
      <Target onClick={playVideo}>
        <StaticImage
          className="video-thumbnail"
          src="../images/YoutubePoster.png"
          alt=""
          quality={98}
          placeholder="none"
        />
        <StaticImage
          className="video-thumbnail-hover"
          src="../images/YoutubePosterHover.png"
          alt=""
          quality={98}
          placeholder="none"
        />
        <iframe
          title="Video about the Bifrost Wallet"
          width={"100%"}
          height={"100%"}
          ref={frame}
        />
      </Target>
    </Container>
  );
}
