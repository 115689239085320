import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { BREAKPOINTS } from "../consts";

const ReviewBox = styled.div`
  border-radius: 12px;
  padding: 1rem;
  box-sizing: border-box;
  text-align: left !important;
  background: rgb(43 44 54 / 40%);
  margin-bottom: 1rem;
  .stars {
    margin-bottom: .4rem;
    > div {
      margin-right: .2rem;
    }
    @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
      margin-bottom: 1rem;
    }
  }
  p {
    opacity: 1;
  }
  .meta {
    display: flex;
    justify-content: space-between;
    opacity: .7;
  }
`;

export default function Review({ review }) {
  return (
    <ReviewBox>
      <div className="stars">
        <StaticImage
          src="../images/RatingStar.png"
          quality={100}
          width={22}
          placeholder="none"
          alt="Star"
        />
        <StaticImage
          src="../images/RatingStar.png"
          quality={100}
          width={22}
          placeholder="none"
          alt="Star"
        />
        <StaticImage
          src="../images/RatingStar.png"
          quality={100}
          width={22}
          placeholder="none"
          alt="Star"
        />
        <StaticImage
          src="../images/RatingStar.png"
          quality={100}
          width={22}
          placeholder="none"
          alt="Star"
        />
        <StaticImage
          src="../images/RatingStar.png"
          quality={100}
          width={22}
          placeholder="none"
          alt="Star"
        />
      </div>
      <span className="h3 heading">{review.heading}</span>
      <p>{review.content}</p>
      <div className="meta">
        <span className="name small">{review.from}</span>
        <span className="date small">{review.date}</span>
      </div>
    </ReviewBox>
  );
}
