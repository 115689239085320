import * as React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../consts";

const Container = styled.div`
  position: relative;
  display: flex;
  text-align: right;
  border-radius: 10px;
  padding: 2.2rem 2rem;
  box-sizing: border-box;
  width: 100%;
  background: rgb(32 30 42 / 60%);
  overflow: hidden;
  div.vertical {
    position: relative;
    z-index: 10;
    width: 70%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex: 1;
  }
  div.desktop-image-container {
    width: 40%;
    display: flex;
    align-items: center;
    .gatsby-image-wrapper {
      transform: scale(1.4) translate(-6%, 1%);
    }
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
    text-align: center;
    padding: 1.8rem 1.5rem;
    div.vertical {
      width: 100%;
      text-align: center;
      > a {
        margin: 1rem auto 1rem;
      }
    }
    div.mobile-image-container {
      margin: 1rem 0;
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    }
  }
`;

const BottomLeftGradient = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  bottom: -43%;
  left: -13%;
  background: radial-gradient(
    circle closest-side,
    rgba(73, 97, 234, 0.3),
    rgba(0, 0, 0, 0) 100%
  );

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    bottom: 0;
    left: -94%;
  }
`;

const TopRightGradient = styled.div`
  position: absolute;
  width: 650px;
  height: 650px;
  top: -80%;
  right: -15%;
  background: radial-gradient(
    circle closest-side,
    rgba(118, 86, 238, 0.4),
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.7;

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    top: -40%;
    right: -128%;
  }
`;

export default function Safe({ children }) {
  return (
    <Container>
      <BottomLeftGradient />
      {children}
      <TopRightGradient />
    </Container>
  );
}
