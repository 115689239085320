import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { APPLELINK, BREAKPOINTS, GOOGLELINK } from "../consts";
import HoverImageLink from "./hover-image-link";
import styled from "styled-components";
import { EVENT, track } from "../fathom";

const Container = styled.div`
  a {
    margin-right: 1rem;
    margin-bottom: 0.5rem;

    @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
      margin: 0;
      &:nth-child(1) {
        margin-right: 0.35rem;
      }
      &:nth-child(2) {
        margin-left: 0.35rem;
      }
    }
  }
`;

export default function AppButtons({ isMobile, loading = 'lazy' }) {
  return (
    <Container isMobile={isMobile}>
      <HoverImageLink
        href={APPLELINK}
        onClick={track(EVENT.APP_STORE)}
        rel="noreferrer"
        title="Download the Bifrost app from App Store"
        name="Download the Bifrost app from App Store"
        target="_blank"
        className={`youtube-logo-container`}
      >
        <StaticImage
          src="../images/Apple.png"
          alt="Download the Bifrost app from App Store"
          className="default-logo"
          height={45}
          quality={100}
          placeholder="none"
          loading={loading}
          />
        <StaticImage
          src="../images/AppleHover.png"
          alt="Download the Bifrost app from App Store"
          className="hover-logo"
          height={45}
          quality={100}
          loading={loading}
          />
      </HoverImageLink>

      <HoverImageLink
        href={GOOGLELINK}
        onClick={track(EVENT.PLAY_STORE)}
        rel="noreferrer"
        title="Download the Bifrost app from Google Play Store"
        name="Download the Bifrost app from Google Play Store"
        target="_blank"
        className={`youtube-logo-container`}
        >
        <StaticImage
          src="../images/Google.png"
          alt="Download the Bifrost app from Google Play Store"
          className="default-logo"
          height={45}
          quality={100}
          placeholder="none"
          loading={loading}
          />
        <StaticImage
          src="../images/GoogleHover.png"
          alt="Download the Bifrost app from Google Play Store"
          className="hover-logo"
          height={45}
          quality={100}
          placeholder="none"
          loading={loading}
        />
      </HoverImageLink>
    </Container>
  );
}
