import * as React from "react";
import Layout from "../components/layout";
import Block from "../components/block";
import styled from "styled-components";
import { BREAKPOINTS } from "../consts";
import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import SplitFlexBox from "../components/split-flex-box";
import Review from "../components/review";
import Safe from "../components/safe";
import DownloadApp from "../components/download-app";
import AppButtons from "../components/app-buttons";
import SmallContentBox from "../components/small-content-box";
import Footer from "../components/footer";
import VideoContainer from "../components/video-container";
import { Gradient, GradientWapper } from "../components/gradients";
import { Link } from "gatsby";
import Decor from "../components/decor";

const DesktopAssetIconSize = 64;
const MobileAssetIconSize = 42;

const Page = styled.div`
  .header {
    .vertical {
      max-width: 25rem;
      margin-right: 3rem;
      padding-right: 1rem;
      flex: 1;

      @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
        text-align: center;
        padding: 3rem 0 0 0 !important;
        margin: 0;
      }
    }
    .screenshot {
      text-align: right;
      padding: 4rem;
      padding-right: 0;
      flex: 0.8;
      box-sizing: border-box;
      @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
        padding: 2.4rem 1.3rem;
        flex: 1;
      }
    }
  }
`;
const StatisticsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .border {
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: #333e8d;
    height: 40%;
  }
  .border {
    height: 60%;
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    margin: 1rem 0;
  }
`;
const Statistic = styled.div`
  padding: 2rem;
  margin: 0.8rem;
  text-align: center;
  .count {
    font-size: 3.75rem;
    line-height: 3.5rem;
    font-weight: 400;
  }
  .name {
    margin-top: 1rem;
    opacity: 0.7;
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    font-size: 0.8rem;
    padding: 0.5rem;
    margin: 0.2rem;
    flex: 1;
    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }
    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
    .count {
      font-size: 1.7rem;
      line-height: 1.8rem;
    }
    .name {
      font-size: 0.75rem;
    }
  }
`;
const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h1,
  > p {
    text-align: center;
  }
  h1 {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    h1 {
      margin-bottom: 1rem;
    }
  }
`;
const ReviewList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 3rem;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    margin-top: 1rem;
  }
`;
const ReviewColumn = styled.div`
  width: 50%;
  padding: 0 0.5rem;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAKPOINTS["tablet"]}px) {
    width: 100%;
    padding: 0;
  }
`;
const CryptoCollection = styled.div`
  text-align: center;
`;
const AssetIconContainer = styled.div`
  > div {
    margin-bottom: 1.6rem;
    > div, > span {
      margin: 0 0.8rem;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    margin-bottom: 3rem;
    > div {
      margin-bottom: 1.2rem;
      > div {
        margin: 0 0.6rem;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

const Reviews = [
  {
    heading: "One of the best apps",
    content:
      "Arguably one of the best user focused wallets out there, works great with the Songbird network",
    from: "Joshed1991",
    date: "5 Nov",
  },
  {
    heading: "Great app with user friendly interface",
    content:
      "The app is brilliant, very easy to use and easy to explain to someone who has little experience. Very easy to delegate too.",
    from: "Darren Pallatina",
    date: "31 Oct",
  },
  {
    heading: "Best products in this space",
    content:
      "So thankful for all of the efforts of you and the team. By far the best product experience in this space. Would happily pay for your apps. Would give 20 stars if I could!!!",
    from: "R.Meredith",
    date: "2 Dec",
  },
  {
    heading: "Proper Job!",
    content:
      "Well what else can I say. Well done Towo Labs, easy to use. Top marks.",
    from: "Forrest Fenn",
    date: "10 Oct",
  },
  {
    heading: "Bifrost. User friendly.",
    content:
      "Top notch wallet, I consider myself a grandad in this space and found this wallet very easy to user. A very well done!",
    from: "Bryall22",
    date: "5 Jan",
  },
  {
    heading: "The Future is Here",
    content:
      "Putting the money system back in the hands of the people! Bifrost is doing a superb job advancing the goals of Web 3.0!",
    from: "ByThePeopleForThePeople",
    date: "23 Sep",
  },
];

const DecorSettings = {
  desktop: [
    {
      colour: "blue",
      size: 25,
      left: -300,
      top: -110,
    },
    {
      colour: "white",
      size: 15,
      left: 190,
      top: -130,
    },
    {
      colour: "white",
      size: 11,
      left: -170,
      top: 74,
    },
    {
      colour: "purple",
      size: 22,
      left: 280,
      top: 90,
    },
  ],
  mobile: [
    {
      colour: "blue",
      size: 14,
      left: -170,
      top: -68,
    },
    {
      colour: "white",
      size: 8,
      left: 82,
      top: -62,
    },
    {
      colour: "white",
      size: 6,
      left: -90,
      top: 36,
    },
    {
      colour: "purple",
      size: 12,
      left: 158,
      top: 0,
    },
  ],
};

const IndexPage = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  return (
    <Page>
      <Layout title="Self-custody, multi-chain crypto wallet for DeFi, NFTs and Web3">
        <GradientWapper>
          <Gradient position="topLeft" color="purple" />
          <Block fullHeight={true}>
            <SplitFlexBox className="header" floatInMiddle={true}>
              <div className="vertical">
                <Decor
                  settings={
                    isMobile ? DecorSettings.mobile : DecorSettings.desktop
                  }
                >
                  <h1
                    style={{
                      margin: "0",
                    }}
                  >
                    A true multi-chain wallet.
                  </h1>
                </Decor>

                <p
                  style={{
                    margin: "1.8rem 0",
                  }}
                >
                  Have full control over your crypto tokens with a self custody
                  wallet, NFT gallery and Web3 browser all in one simple and
                  secure app.
                </p>
                <AppButtons loading="eager" />
              </div>
              <div className="screenshot">
                <StaticImage
                  src="../images/MobileAppScreenshot.png"
                  quality={80}
                  imgStyle={{ objectFit: "contain" }}
                  placeholder="blurred"
                  alt="Screenshot from the Bifrost Wallet mobile application."
                  loading="eager"
                />
              </div>
            </SplitFlexBox>
          </Block>
          <Gradient position="bottomRight" color="blue" />
        </GradientWapper>

        <Block noPadding={true}>
          <StatisticsContainer>
            <Statistic>
              <div className="count heading">1,000+</div>
              <div className="name">Assets</div>
            </Statistic>
            <div className="border"></div>
            <Statistic>
              <div className="count heading">14</div>
              <div className="name">Blockchains</div>
            </Statistic>
            <div className="border"></div>
            <Statistic>
              <div className="count heading">250K+</div>
              <div className="name">Installs</div>
            </Statistic>
          </StatisticsContainer>
        </Block>

        <GradientWapper>
          <Gradient position="bottomLeft" color="purple" />
          <Block justifyContent={"center"}>
            <SmallContentBox>
              <div className="text-container">
                <h2>Control your Crypto</h2>
                <p>
                  Bifrost Wallet is a self custody cypto wallet, with you in
                  complete control of your crypto tokens, keys and data.
                </p>
                <a
                  href="https://support.bifrostwallet.com/en/articles/6877393-the-importance-of-self-custody"
                  title="More on self custody"
                  target={"_blank"}
                  rel="noreferrer"
                  className="button"
                >
                  <span>More on self custody</span>
                  <StaticImage
                    src="../images/LinkIcon.png"
                    alt=""
                    className="external-arrow"
                    height={18}
                    quality={100}
                    placeholder="none"
                  />
                  <div className="overlay"></div>
                </a>
              </div>
              <div className="image-container">
                <StaticImage
                  src="../images/ControlYourCrypto.png"
                  quality={80}
                  placeholder="none"
                  alt="Bifrost Wallet supports many crypto-currencies including Bitcoin, Ethereum and Songbird."
                />
              </div>
            </SmallContentBox>
          </Block>
        </GradientWapper>

        <Block>
          <SmallContentBox>
            <div className="image-container">
              <StaticImage
                src="../images/DappBrowser.png"
                quality={80}
                placeholder="none"
                alt="Dapp Browser supported applications"
              />
            </div>
            <div className="text-container">
              <h2>Browser for Dapps</h2>
              <p>
                Access to staking, swaps and more. Discover and use any Web3 application such as Uniswap,
                Compound and Flarefarm.
              </p>
              <a
                href="https://support.bifrostwallet.com/en/articles/6877428-browse-and-access-decentralized-applications-dapps"
                title="Dapps in Bifrost Wallet"
                target={"_blank"}
                rel="noreferrer"
                className="button"
              >
                <span>Dapps in Bifrost Wallet</span>
                <StaticImage
                  src="../images/LinkIcon.png"
                  alt=""
                  className="external-arrow"
                  height={18}
                  quality={100}
                  placeholder="none"
                />
                <div className="overlay"></div>
              </a>
            </div>
          </SmallContentBox>
        </Block>

        <GradientWapper>
          <Block>
            <SmallContentBox>
              <div className="text-container">
                <h2>Store your NFTs</h2>
                <p>
                  Securely store and easily view your rare NFTs from games,
                  artists and beyond within the Bifrost app.
                </p>
                <a
                  href="https://support.bifrostwallet.com/en/articles/6872887-manage-your-nfts"
                  title="Wallet for NFTs"
                  target={"_blank"}
                  rel="noreferrer"
                  className="button"
                >
                  <span>Wallet for NFTs</span>
                  <StaticImage
                    src="../images/LinkIcon.png"
                    alt=""
                    className="external-arrow"
                    height={18}
                    quality={100}
                    placeholder="none"
                  />
                  <div className="overlay"></div>
                </a>
              </div>
              <div className="image-container">
                <StaticImage
                  src="../images/StoreYourNFTs.png"
                  quality={80}
                  placeholder="none"
                  alt="NFT storage within the Bifrost app"
                />
              </div>
            </SmallContentBox>
          </Block>
          <Gradient position="bottomRight" color="purple" />
        </GradientWapper>

        <Block>
          <Safe>
            {!isMobile && (
              <div className="desktop-image-container">
                <StaticImage
                  src="../images/Safe.png"
                  quality={80}
                  alt="Bifrost Wallet Security"
                  placeholder="none"
                />
              </div>
            )}
            <div className="vertical">
              <h2>
                Find out what we do to make your crypto self custody more secure
              </h2>
              {isMobile && (
                <div className="mobile-image-container">
                  <StaticImage
                    src="../images/Safe.png"
                    quality={80}
                    alt="Bifrost Wallet Security"
                    placeholder="none"
                  />
                </div>
              )}
              <p>
                From removing personal data tracking to presenting clear
                transaction details, we take great care in making sure your
                security and privacy are well protected.
              </p>
              <Link to="/security/" className="button">
                <span>More on security</span>
                <div className="overlay"></div>
              </Link>
            </div>
          </Safe>
        </Block>

        <Block flexDirection={"row"}>
          <div
            style={{ width: "100%", textAlign: "center", maxWidth: "42rem" }}
          >
            <h1>What is Bifrost Wallet?</h1>
            <VideoContainer></VideoContainer>
          </div>
        </Block>

        <GradientWapper>
          <Gradient position="topLeft" color="purple" />
          <Block>
            <CryptoCollection>
              <h1>More chains, more tokens</h1>
              <p>
                Store your favourite tokens like Flare, Songbird and Ethereum
                securely.
              </p>
              <AssetIconContainer>
                {isMobile ? (
                  <>
                    <div>
                      <StaticImage
                        src="../images/asset-icons/Algo.png"
                        quality={100}
                        placeholder="none"
                        alt="Algorand (ALGO)"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/XLM.png"
                        quality={100}
                        placeholder="none"
                        alt="Stellar (XLM)"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/FLR.png"
                        quality={100}
                        placeholder="none"
                        alt="Flare (FLR)"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/SGB.png"
                        quality={100}
                        placeholder="none"
                        alt="Songbird (SGB)"
                        height={MobileAssetIconSize}
                      />
                    </div>
                    <div>
                      <StaticImage
                        src="../images/asset-icons/BNB.png"
                        quality={100}
                        placeholder="none"
                        alt="BNB"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/XRP.png"
                        quality={100}
                        placeholder="none"
                        alt="XRP"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/Matic.png"
                        quality={100}
                        placeholder="none"
                        alt="Polygon (MATIC)"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/LTC.png"
                        quality={100}
                        placeholder="none"
                        alt="Litecoin (LTC)"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/Doge.png"
                        quality={100}
                        placeholder="none"
                        alt="Dogecoin (DOGE)"
                        height={MobileAssetIconSize}
                      />
                    </div>
                    <div>
                      <StaticImage
                        src="../images/asset-icons/ETH.png"
                        quality={100}
                        placeholder="none"
                        alt="Ethereum (ETH)"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/XDC.png"
                        quality={100}
                        placeholder="none"
                        alt="XDC (XDC)"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/BTC.png"
                        quality={100}
                        placeholder="none"
                        alt="Bitcoin (BTC)"
                        height={MobileAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/FTM.png"
                        quality={100}
                        placeholder="none"
                        alt="Fantom (FTM)"
                        height={MobileAssetIconSize}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <StaticImage
                        src="../images/asset-icons/Algo.png"
                        quality={100}
                        alt="Algorand (ALGO)"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/XLM.png"
                        quality={100}
                        alt="Stellar (XLM)"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/FLR.png"
                        quality={100}
                        alt="Flare (FLR)"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/SGB.png"
                        quality={100}
                        alt="Songbird (SGB)"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                    </div>
                    <div>
                      <StaticImage
                        src="../images/asset-icons/BNB.png"
                        quality={100}
                        alt="BNB"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/XRP.png"
                        quality={100}
                        alt="XRP"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/Matic.png"
                        quality={100}
                        alt="Polygon (MATIC)"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/LTC.png"
                        quality={100}
                        alt="Litecoin (LTC)"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                      <span className="doge-hover">
                        <div className="doge-container"></div>
                        <StaticImage
                          src="../images/asset-icons/Doge.png"
                          quality={100}
                          alt="Dogecoin (DOGE)"
                          placeholder="none"
                          height={DesktopAssetIconSize}
                          imgClassName="doge-hover"
                        />
                      </span>
                    </div>
                    <div>
                      <StaticImage
                        src="../images/asset-icons/ETH.png"
                        quality={100}
                        alt="Ethereum (ETH)"
                        placeholder="none"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/XDC.png"
                        quality={100}
                        placeholder="none"
                        alt="XDC (XDC)"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/BTC.png"
                        quality={100}
                        placeholder="none"
                        alt="Bitcoin (BTC)"
                        height={DesktopAssetIconSize}
                      />
                      <StaticImage
                        src="../images/asset-icons/FTM.png"
                        quality={100}
                        placeholder="none"
                        alt="Fantom (FTM)"
                        height={DesktopAssetIconSize}
                      />
                    </div>
                  </>
                )}
              </AssetIconContainer>
              <Link to="/supported-assets/" className="button">
                <span>See all supported tokens</span>
                <div className="overlay"></div>
              </Link>
            </CryptoCollection>
          </Block>
        </GradientWapper>

        <GradientWapper>
          <Gradient position="topRight" color="blue" />
          <Block>
            <ReviewsContainer>
              <h1>Trusted &amp; loved</h1>
              <p>See what people have said about Bifrost Wallet.</p>
              <ReviewList>
                <ReviewColumn>
                  {Reviews.map((review, i) =>
                    i % 2 === 0 ? <Review key={i} review={review}></Review> : ""
                  )}
                </ReviewColumn>
                <ReviewColumn>
                  {Reviews.map((review, i) =>
                    i % 2 !== 0 ? <Review key={i} review={review}></Review> : ""
                  )}
                </ReviewColumn>
              </ReviewList>
            </ReviewsContainer>
          </Block>
        </GradientWapper>

        <div id="download-app"></div>

        <Block>
          <DownloadApp>
            <div className="image-container">
              <StaticImage
                src="../images/DownloadApp.png"
                quality={100}
                alt="Download Bifrost Wallet"
                placeholder="none"
              />
            </div>
            <div className="vertical">
              <h1>Download app</h1>
              <p style={{ marginBottom: ".2rem" }}>
                Available on iOS and Android devices.
              </p>
              <div className="app-buttons-container">
                <AppButtons />
              </div>
            </div>
          </DownloadApp>
        </Block>
      </Layout>
      <Footer />
    </Page>
  );
};

export default IndexPage;
